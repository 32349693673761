import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { criarElementoDeVideo } from "../functions/videoUtils";
import { addCamera } from "../functions/webrtcUtils";
import styles from "./Cameras.module.css";
import NextPage from "../assets/icons/cameras/nextPage.svg";
import PreviousPage from "../assets/icons/cameras/previousPage.svg";

const URL_DM = process.env.REACT_APP_URL_DM;

const Camera = () => {
  const [user, setUser] = useState({});
  const [gridSize, setGridSize] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [cameras, setCameras] = useState([]);
  const [allDevicesOffline, setAllDevicesOffline] = useState(false);
  const webRTCAdaptors = useRef({});

  useEffect(() => {
    const storedUser = JSON.parse(sessionStorage.getItem("user"));
    if (storedUser) {
      setUser(storedUser);
    }
  }, []);

  useEffect(() => {
    const fetchDevices = async () => {
      if (!user.id_user) return;
      try {
        const response = await axios.get(`${URL_DM}/api/get_devices/${user.id_user}?status=online`);
        const devices = response.data;
        devices.forEach(async (device) => {
          await addCamera(device, webRTCAdaptors, setCameras, criarElementoDeVideo);
        });
      } catch (error) {
        if (error.response.data.response === "No devices found!") {
          setAllDevicesOffline(true);
        }
        console.log(error);
      }
    };

    fetchDevices();
  }, [user]);

  useEffect(() => {
    cameras.forEach((camera) => {
      const video = camera.videoContainer.querySelector("video");
      if (video && video.paused) {
        video.play().catch((error) => console.error("Autoplay error:", error));
      }
    });
  }, [cameras, currentPage, gridSize]);

  const handleGridSizeChange = (size) => {
    setCurrentPage(0);
    setGridSize(size);
  };

  const goToPreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if ((currentPage + 1) * gridSize < cameras.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const startIndex = currentPage * gridSize;
  const endIndex = Math.min(startIndex + gridSize, cameras.length);
  const emptyGridItems = gridSize - (endIndex - startIndex);

  return (
    <div className={styles.container}>
      {allDevicesOffline ? (
        <div className={styles.offlineMessage}>
          <img src="/logo192.png" alt="SST Logo" />
          <span>Nenhum dispositivo online encontrado.</span>
        </div>
      ) : (
        <>
          <div className={styles.cameraView}>
            <div className={styles.content}>
              <div className={styles.camerasView} data-grid-size={gridSize} style={{ height: "100%" }}>
                {cameras.slice(startIndex, endIndex).map((camera) => (
                  <div key={camera.id} className={styles.camera}>
                    <div
                      ref={(el) => {
                        if (el && !el.hasChildNodes()) {
                          const video = camera.videoContainer.querySelector("video");
                          if (video) {
                            video.classList.add(styles.video);
                          }
                          el.appendChild(camera.videoContainer);
                        }
                      }}
                      style={{ height: "100%", overflow: "hidden" }}
                    />
                  </div>
                ))}
                {emptyGridItems > 0 &&
                  Array.from({ length: emptyGridItems }).map((_, index) => <div key={`empty-${index}`} />)}
              </div>
            </div>
          </div>
          <div className={styles.controls}>
            <div className={styles.leftControl}>
              <div className={styles.pageControl}>
                <div className={styles.navigationControls}>
                  <button onClick={goToPreviousPage} disabled={currentPage === 0}>
                    <img src={PreviousPage} alt="Previous Page" />
                  </button>
                  <button onClick={goToNextPage} disabled={startIndex + gridSize >= cameras.length}>
                    <img src={NextPage} alt="Next Page" />
                  </button>
                </div>
              </div>
            </div>
            <div className={styles.centerControl}>
              <div className={styles.grid_controls}>
                <button onClick={() => handleGridSizeChange(1)}>
                  <svg width="35" height="24" viewBox="0 0 35 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" width="34" height="24" rx="2" fill="white" />
                  </svg>
                </button>
                <button onClick={() => handleGridSizeChange(4)}>
                  <svg width="35" height="24" viewBox="0 0 35 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" width="34" height="24" rx="2" fill="white" />
                    <line x1="17.25" y1="24" x2="17.25" stroke="#100F17" strokeWidth="0.5" />
                    <line x1="34.5" y1="12.25" x2="0.5" y2="12.25" stroke="#100F17" strokeWidth="0.5" />
                  </svg>
                </button>
                <button onClick={() => handleGridSizeChange(9)}>
                  <svg width="35" height="24" viewBox="0 0 35 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" width="34" height="24" rx="2" fill="white" />
                    <line x1="34.5" y1="16.25" x2="0.5" y2="16.25" stroke="#100F17" strokeWidth="0.5" />
                    <line x1="11.25" y1="24" x2="11.25" stroke="#100F17" strokeWidth="0.5" />
                    <line x1="34.5" y1="8.25" x2="0.5" y2="8.25" stroke="#100F17" strokeWidth="0.5" />
                    <line x1="23.25" y1="24" x2="23.25" stroke="#100F17" strokeWidth="0.5" />
                  </svg>
                </button>
                <button onClick={() => handleGridSizeChange(16)}>
                  <svg width="35" height="24" viewBox="0 0 35 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" width="34" height="24" rx="2" fill="white" />
                    <line x1="34.5" y1="19.25" x2="0.5" y2="19.25" stroke="#100F17" strokeWidth="0.5" />
                    <line x1="16.25" y1="24" x2="16.25" stroke="#100F17" strokeWidth="0.5" />
                    <line x1="8.25" y1="24" x2="8.25" stroke="#100F17" strokeWidth="0.5" />
                    <line x1="34.5094" y1="6.5" x2="0.498158" y2="6.5" stroke="#100F17" strokeWidth="0.5" />
                    <line x1="34.5094" y1="12.5" x2="0.498158" y2="12.5" stroke="#100F17" strokeWidth="0.5" />
                    <line x1="25.25" y1="24" x2="25.25" stroke="#100F17" strokeWidth="0.5" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Camera;
