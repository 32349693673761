import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./Login.css";
import { generateToken } from "../firebase/firebaseConfig";

const URL_DM = process.env.REACT_APP_URL_DM;

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState("");
  const [userToken, setUserToken] = useState("token_not_generated");
  const navigate = useNavigate();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [notificationEnabled, setNotificationEnabled] = useState(false);

  const images = Array.from({ length: 4 }, (_, index) => `/images/backgrounds/background-${index + 1}.png`);

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const generatedToken = await generateToken();
        if (generatedToken !== undefined) {
          setUserToken(generatedToken);
          setNotificationEnabled(true);
        }
      } catch (error) {
        console.error("Error generating token:", error);
      }
    };

    fetchToken();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [images.length]);

  useEffect(() => {
    const user = sessionStorage.getItem("user");
    if (user) {
      navigate("/dashboard");
    }
  }, [navigate]);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setLoading(true);
      const response = await axios.post(`${URL_DM}/api/login`, {
        deviceID: userToken,
        email: email,
        password: password,
        device_type: "web",
        device_model: "browser",
      });
      const user_data = response.data.user_data;
      user_data["notificationEnabled"] = notificationEnabled;

      if (
        response.data.response === "Login successful" ||
        response.data.response === `Device with id: ${response.data.device.deviceID} create device and login successful`
      ) {
        sessionStorage.setItem("user", JSON.stringify(user_data));
        navigate("/dashboard");
        window.location.reload();
      } else {
        setError("Login failed. Please check your credentials.");
      }
    } catch (error) {
      setError(error.response?.data?.response || "An error occurred during login. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const bannerStyle = {
    backgroundImage: `url(${images[currentImageIndex]})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  return (
    <div className="login">
      <div className="login_Content">
        <div className="login_Form">
          <img src="/images/logo/SST.png" alt="SST" />
          <form onSubmit={handleSubmit} name="login">
            <label htmlFor="email">E-mail*</label>
            <input
              type="email"
              placeholder="Enter your E-mail"
              id="email"
              value={email}
              onChange={handleEmailChange}
              required
            />
            <label htmlFor="password">Password*</label>
            <input
              type="password"
              placeholder="Enter your password"
              id="password"
              value={password}
              onChange={handlePasswordChange}
              required
            />
            <div className="checkbox-container">
              <input
                className="login_CheckBox"
                type="checkbox"
                id="rememberMe"
                checked={rememberMe}
                onChange={handleRememberMeChange}
              />
              <label className="checkbox-label" htmlFor="rememberMe">
                Remember me for 30 days
              </label>
            </div>
            <button className="submit-btn" type="submit">
              {loading ? <img className="loading-button" src="/loading.svg" alt="loading" /> : "Login"}
            </button>
            {error && <p className="error-message">{error}</p>}
          </form>
        </div>
        <div className="login_Banner" style={bannerStyle}>
          <h1>Bem vindo à SST!</h1>
          <p>
            Somos especialistas em resolver problemas usando inovadoras tecnologias alimentadas por IA para aprimorar o
            seu negócio.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Login;
